"use client";

import { Carousel } from "flowbite-react";
import { emmanuelimg, eventheroImg } from "../../constants/imports";
import Button from "./Button";
import { IoIosArrowRoundForward } from "react-icons/io";
import CountdownTimer from "./CountdownTimer";
import { useNavigate } from "react-router-dom";

export function Carouselx() {
  const EventdateCom = () => (
    <div className="py-2 border border-white/60 w-[70%] place-self-start flex justify-center items-center  rounded-lg">
      <p className="text-base">
        Remote WebNet Dynamics Mentorship Programme |{" "}
        <span className="font-[400] text-[#EBE126]">July 5th 2025</span>
      </p>
    </div>
  );

  return (
    <div className=" w-screen  flex justify-center items-center ">
      <div className="flex justify-between flex-col md:flex-row w-full h-full gap-x-16">
        <div className="w-full md:w-3/4">
          <Carousel  leftControl=" " rightControl=" ">
            <div className="p-3 flex flex-col text-white  ">
              <EventdateCom />
              <h2 className="text-white font-bold text-[42px] md:w-3/4 leading-[55px]">
                Advance Your Skills with Industry Experts
              </h2>
              <p className="text-white text-base ">
                Empower your tech journey with hands-on learning and strategic
                insights from top professionals.
              </p>
              <CTAButtons />
            </div>
            <div className="p-3 flex flex-col   text-white">
              <EventdateCom />

              <h2 className="text-white font-bold text-[42px] md:w-3/4   leading-[55px]">
                Your launchpad for accelerated career growth
              </h2>
              <p className="text-white text-base ">
                Empowering Future Tech Leaders – One Day, Unlimited
                Possibilities!
              </p>
              <CTAButtons />
            </div>
            <div className="p-3 flex  flex-col  text-white">
              <EventdateCom />

              <h2 className="text-white font-bold text-[42px] md:w-3/4   leading-[55px]">
                Elevate your skills, expand your horizon
              </h2>
              <p className="text-white text-base   ">
                Secure your spot today and take the next step in your tech
                journey!
              </p>
              <CTAButtons />
            </div>
          </Carousel>
        </div>

        <img src={eventheroImg} />
      </div>
    </div>
  );
}

export const CTAButtons = ({dark}) => {

  const navigate =useNavigate()
  return (
    <div className="flex md:gap-3 flex-col md:flex-row ">
      <Button disabled={true} width={200} text="Register" onBtnClick={() => {
        navigate("/mentorship2025/register")

      }} />
      <Button
        width={200}
        outlined
        text={
          <p className={`flex transition  ${dark?"text-gray-800":""} duration-500  h-full  hover:gap-4`}>
            
            Learn more <IoIosArrowRoundForward className="inline " />
          </p>
        }
        onBtnClick={() => {

navigate("/mentorship2025")
        }}
      />
    </div>
  );
};
