import {
  cloud,
  design,
  mobile,
  web,
  scl,
  fireWall,
  edlovprod,
  sclprod,
  goldfieldprod,
  arc,
  teamDG,
  teamSI,
  teamJA,
  teamMD,
  palladium,
  fmb,
  kobokolet,
  consultancy,
  automation,
  cyber,
  hardware,
  transformation,
  infrastructure,
  koboPayProd,
  kobokoletProd,
  excellence,
  teamwork,
  integrity,
  communication,
  client,
  innovation,
  ITSupport,
  QAssurance,
  CCentric,
  TExperts,
  CEdge,
  onCallSupport,
  customSoftware,
  webDesign,
  cloudImg,
  cyberSecurity,
  dan,
  eyo,
  ibrahim,
  homeAutomation,
  difitialTrans,
  wwd1,
  wwd2,
  wwd3,
  wwd4,
  wwd5,
  wwd6,
  wwd7,
  wwd8,
  wwd9,
  wwd10,
  kobolite,
  ibrahimimg,
  emmanuelimg,
} from "./imports";

const servicesData = [
  {
    title: "UI/UX & Product Design",
    img: design,
    desc: "we specialize in elevating digital experiences through cutting-edge Design solutions.",
  },
  {
    title: "Mobile App Development",
    img: mobile,
    desc: "excel in crafting innovative and functional mobile applications that bring your ideas to life.",
  },
  {
    title: "Cloud Architecture",
    img: cloud,
    desc: "Empower your business with scalable solutions through our Cloud Architecture services ",
  },
  {
    title: "Web Development",
    img: web,
    desc: "we are your trusted partner for robust and dynamic web development solutions.",
  },
];

const innovationCategory = [
  "Sustanable development & climate tech",
  "Social impact & civic tech",
  "Health & well being",
  "future of work & AI Innovation",
  "Edtech & digital learning",
  "E-commerce & supply chain innovation",
  "Fintech & economic empowerment",
  "Space tech & advanced technologies",
  "Smart city & infrastructure",
  "Cybersecurity & digital privacy",
  "Creative tech & entertainment",
];
const clientData = [scl, arc, palladium, fmb, kobokolet];
const moreData = [
  {
    title: "Consultancy",
    img: consultancy,
    desc: "Our consultancy services are the cornerstone of our offerings. With a deep understanding of industry trends and best practices, we provide tailored guidance to help businesses make informed decisions. Our experts collaborate closely with clients, offering strategic insights, technology roadmaps, and optimization strategies to align IT initiatives with organizational goals.",
  },
  {
    title: "Tech Support",
    img: automation,
    desc: "Experience the future with our home automation solutions. We transform living spaces into smart environments, seamlessly integrating devices and systems for enhanced comfort, energy efficiency, and security. From smart lighting and thermostats to home security and entertainment systems, we create a connected home tailored to your preferences.",
  },
  {
    title: "Cloud Services",
    img: cyber,
    desc: "Protect your digital assets from evolving threats with our cybersecurity services. We conduct comprehensive risk assessments, implement robust security measures, and provide ongoing monitoring to safeguard your systems, data, and sensitive information.",
  },
  {
    title: "Supply & Installation of ICT Gadget",
    img: hardware,
    desc: "Our company offers the best and most reliable laptops, desktops, IT hardware/software, smart home services, IP phones services and firewalls available. Our products are tailored to meet your specific needs, ensuring that your business runs smoothly and efficiently.",
  },
  {
    title: "Digital Transformation Services:",
    img: transformation,
    desc: "Stay ahead of the curve with our digital transformation services. We guide organizations through technological shifts, helping them adopt new technologies, optimize processes, and unlock new opportunities for growth.",
  },
  {
    title: "IT Infrastructure Management",
    img: infrastructure,
    desc: "A robust IT infrastructure is the backbone of any successful organization. Our experts design, implement, and manage IT infrastructures that are scalable, secure, and optimized for performance. From network architecture to server management, we ensure your IT environment is primed for success.",
  },
];
const productData = [
  {
    title: "KoboKolet",
    img: kobokoletProd,
    url: "https://play.google.com/store/apps/details?id=com.kobokolet&hl=en",
    desc: "Kobokolet is a tool to register small holder farmers and their farms in the KoboWeb ecosystem, enabling them to access 0% input loans, improve market connections, and achieve financial inclusion.",
  },
  {
    title: "KoboPay",
    img: koboPayProd,
    url: "https://kobopay.co/home",
    desc: "KoboPay Fintech Solution,  A Financial Technology Solution  Built for everyone. We let you bank with ease even when without an internet connection. ",
  },
  {
    title: "KoboLite",
    img: kobolite,
    url: "https://play.google.com/store/apps/details?id=com.kobolite.koboweb&hl=en&pli=1",
    desc: "Allows Field Agent assistant carryout day to day actives on their various agents",
  },
  {
    title: "Sai’I’Anwara’I Ju’Mai Consultaire Limited (SCL)",
    img: sclprod,
    url: "https://sclng.com/",
    desc: "An Agro-Allied Company with investment and developmental focus on “people, crops, livestock and environment ",
  },
  {
    title: "Eldov Transnational Service Ltd",
    img: edlovprod,
    url: "https://eldov.org/",
    desc: "ELDOV TRANSNATIONAL SERVICES LIMITED is wholly an indigenous multi-disciplinary firm incorporated here in Nigeria",
  },

  {
    title: "GoldField & Springs Sols",
    img: goldfieldprod,
    url: "https://goldfieldsols.com/",
    desc: "GOLDFIELD & SPRINGS SOLICITORS is a law firm that offers integrated legal services with an emphasis on exceptional client service.",
  },
];
const footerSocialLinkData = [
  { title: "LinkedIn", path: "https://www.linkedin.com/company/remotewebnet" },
  {
    title: "Facebook",
    path: "https://www.facebook.com/share/SYnwZY6YFnprTdDD/?mibextid=qi2Omg",
  },
  { title: "X(Twitter)", path: "https://x.com/Rwndynamics" },
  {
    title: "Instagram",
    path: "https://www.instagram.com/remotewebnetdynamics",
  },
];
const menuItems = [
  { title: "Products", path: "/products" },
  { title: "Partners", path: "/partners" },
  { title: "Blog", path: "/blog" },
];
export const Events = [{ title: "Mentorship Programme 2025", path: "/events" }];

const mentorship2025Details = {
  time: "9:00 AM",
  date: "Saturday, 5th July 2025",
  venue:
    "Zambezi Crescent, 1st floor Murjanatu House, Maitama, FCT Abuja",
};
const coresData = [
  {
    title: "Excellence",
    img: excellence,
    desc: "We strive for excellence in everything we do, providing top-notch services that exceed client expectations.",
  },
  {
    title: "Creativity",
    img: innovation,
    desc: " We promote a collaborative and supportive work environment, valuing the contributions of each team member",
  },
  {
    title: "Continuity",
    img: teamwork,
    desc: " We conduct our business with utmost honesty, transparency, and ethical practices.",
  },

  {
    title: "Results",
    img: integrity,
    desc: "We put our clients' needs at the forefront and tailor solutions that meet their specific requirements. Treating our clients, partners, and employees with respect and dignity, fostering a positive and inclusive atmosphere",
  },
  {
    title: "Consistency",
    img: client,
    desc: "We embrace innovation and continuously seek new ways to enhance our services and deliver value to our clients.",
  },
];
const coresDataold = [
  {
    title: "Excellence",
    img: excellence,
    desc: "We strive for excellence in everything we do, providing top-notch services that exceed client expectations.",
  },
  {
    title: "Teamwork",
    img: teamwork,
    desc: " We promote a collaborative and supportive work environment, valuing the contributions of each team member",
  },
  {
    title: "Integrity",
    img: integrity,
    desc: " We conduct our business with utmost honesty, transparency, and ethical practices.",
  },
  {
    title: "Communication",
    img: communication,
    desc: "At RemotewebNET Dynamics Ltd., we uphold communication as a paramount core value, fostering seamless collaboration and understanding across our IT teams, clients, and stakeholders.",
  },
  {
    title: "Client-Centric",
    img: client,
    desc: "We put our clients' needs at the forefront and tailor solutions that meet their specific requirements. Treating our clients, partners, and employees with respect and dignity, fostering a positive and inclusive atmosphere",
  },
  {
    title: "Innovation",
    img: innovation,
    desc: "We embrace innovation and continuously seek new ways to enhance our services and deliver value to our clients.",
  },
];
const services = [
  {
    header: "IT Support Services",
    slug: "it-support",
    imgUrl: ITSupport,
    content1:
      "RemotewebNET provides a wide range of IT solutions for both small and large businesses. From hardware and software installation to cloud-based networking and data storage, our team of experienced IT professionals can help you find the right solution for your business needs. We can provide reliable and secure solutions designed to help you maximize efficiency and save time and money.",
    content2:
      "Our dedicated support staff is always on hand to provide troubleshooting and maintenance services to ensure your IT system remains running smoothly and optimally. Invest in our reliable IT solutions today and stay at the forefront of success with RemotewebNET!",
  },
  {
    header: "On Call Support",
    slug: "it-support",
    imgUrl: onCallSupport,
    content1:
      "Any technical glitch, even one that lasts only a few minutes, can have an impact on the future of your entire company, particularly if it arises at a crucial time or at an important event. It is essential that such mistakes do not ruin the future of your entire firm. With our  on-call  IT  support  services  in  Nigeria,  RemotewebNET helps  clients  quickly  and professionally to prevent such hardware breakdowns..",
    content2:
      "As a premier IT service provider in Nigeria, it is our goal to offer our clients proactive support that enables them to maintain productivity and corporate success. we guarantee that our team of specialists will are available for immediate on-call IT help at all times, regardless of how big or minor the issue may be",
  },
  {
    header: "Custom Software Solution",
    slug: "software",
    imgUrl: customSoftware,
    content1:
      "At RemotewebNET, we provide innovative software solutions designed to improve our customers’ businesses. Our solutions are tailored to meet the specific needs of our clients, offering greater efficiency and productivity. We use cutting-edge technologies, such as artificial intelligence and machine learning, to create powerful solutions that optimize performance and enhance user experience. We understand the importance of security and strive to keep our customer data safe and secure.",
    content2:
      "We employ rigorous safety measures to protect our systems and guard against potential threats. We are committed to providing our customers with a reliable and secure experience when using our software solutions.",
  },
  {
    header: "Web Design and Development",
    slug: "software",
    imgUrl: webDesign,
    content1:
      "RemotewebNET is a trustworthy Crisis Management Partner for businesses in Abuja, Nigeria. We develop all-in-one powerful solutions for emergencies and critical situations, allowing you to respond quickly and appropriately in such a situation. Businesses may set up fully controlled operator workstations and real-time control rooms using our advanced crisis management platform software.",
    content2:
      "Our crisis experts assess your existing infrastructure risks and emergency readiness to build, manage, and support a crisis management solution that is tailored to your specific needs.",
  },
  {
    header: "Cloud Solutions",
    slug: "cloud-servers",
    imgUrl: cloudImg,
    content1:
      "Transition to the cloud with confidence through our cloud solutions. Whether it's migrating applications and data or setting up cloud-based infrastructure, we help you harness the flexibility, scalability, and cost-efficiency of cloud computing.",
    content2: "",
  },
  {
    header: "Cyber Security",
    slug: "cyber-security",
    imgUrl: cyberSecurity,
    content1:
      "Protect your digital assets from evolving threats with our cybersecurity services. We conduct comprehensive risk assessments, implement robust security measures, and provide ongoing monitoring to safeguard your systems, data, and sensitive information.",
    content2: "",
  },

  {
    header: "Next-Gen Firewall",
    slug: "cyber-security",
    imgUrl: fireWall,
    content1:
      "RemotewebNET is a trustworthy Crisis Management Partner for businesses in Abuja, Nigeria. We develop all-in-one powerful solutions for emergencies and critical situations, allowing you to respond quickly and appropriately in such a situation.\
\
Businesses may set up fully controlled operator workstations and real-time control rooms using our advanced crisis management platform software. Our crisis experts assess your existing infrastructure risks and emergency readiness to build, manage, and support a crisis management solution that is tailored to your specific needs. \
We provide complete visualization tools for your control rooms, allowing you to monitor your business-critical activities from a single screen or shared screen wall. We also offer advice and training on how to recover from such catastrophes with as little disruption or loss of business as possible.RemotewebNET manages all aspects of your crisis management and business continuity activities, backed by intelligence-driven solutions. Work with us to protect your company.",
    content2: "",
  },

  {
    header: "Home Automation",
    slug: "smart-home",
    imgUrl: homeAutomation,
    content1:
      "Experience the future with our home automation solutions. We transform living spaces into smart environments, seamlessly integrating devices and systems for enhanced comfort, energy efficiency, and security. From smart lighting and thermostats to home security and entertainment systems, we create a connected home tailored to your preferences.",
    content2: "",
  },

  {
    header: "Digital Transformation Services",
    slug: "digital-transformation",
    imgUrl: difitialTrans,
    content1:
      "Stay ahead of the curve with our digital transformation services. We guide organizations through technological shifts, helping them adopt new technologies, optimize processes, and unlock new opportunities for growth.",
    content2: "",
  },
  {
    header: "Consultancy",
    slug: "consultancy",
    imgUrl: webDesign,
    content1:
      "Our consultancy services are geared towards providing strategic guidance, helping businesses make informed decisions to drive growth and innovation.",
    content2: "",
  },
  {
    header: "Cloud Solutions",
    slug: "cloud-solutions",
    imgUrl: webDesign,
    content1:
      "Our cloud solutions empower businesses to leverage the flexibility, scalability, and collaboration capabilities of cloud technologies.",
    content2: "",
  },

  {
    header: "IT Infrastructure",
    slug: "it-infrastucture",
    imgUrl: webDesign,
    content1:
      "Our infrastructure management services cover the entire lifecycle of IT infrastructure, from initial setup to ongoing optimization.",
    content2: "",
  },
];

const HeroServices = [
  {
    features: (
      <>
        <li>
          Proactive Monitoring: We monitor your systems in real-time,
          identifying and addressing potential issues before they impact your
          operations.
        </li>
        <li>
          {" "}
          Remote Assistance: Quick and efficient remote support to resolve
          issues without disrupting your workflow.
        </li>
        <li>
          {" "}
          On-Site Support: When necessary, our expert technicians are available
          for on-site support to handle complex issues or installations.
        </li>
      </>
    ),
    benefits: (
      <>
        {" "}
        <li>
          Increased Efficiency: With reliable IT support, your systems operate
          at peak efficiency, allowing you to focus on core business activities.{" "}
        </li>
        <li>
          Cost Savings: Proactive monitoring and remote support reduce the need
          for frequent on- site visits, leading to cost savings.
        </li>
      </>
    ),
    slug: "it-infrastucture",
    title: "IT Infrastructure Management",
    subtitle:
      "Our infrastructure management services cover the entire lifecycle of IT infrastructure, from initial setup to ongoing optimization.",
    bgimg: "",
  },
  {
    slug: "consultancy",
    features: (
      <>
        <li>
          Needs Assessment: In-depth analysis to understand your business goals,
          challenges, and technological requirements.{" "}
        </li>{" "}
        <li>
          Strategic Planning: Development of customized strategies to optimize
          IT infrastructure, adopt emerging technologies, and achieve long-term
          objectives.
        </li>
      </>
    ),
    benefits: (
      <>
        <li>
          Informed Decision-Making: Make strategic decisions backed by expert
          insights and data.
        </li>
        <li>
          {" "}
          Future-Proofing: Develop plans that accommodate future technological
          advancements and business expansion.{" "}
        </li>
      </>
    ),
    title: "Consultancy",
    subtitle:
      "Our consultancy services are geared towards providing strategic guidance, helping businesses make informed decisions to drive growth and innovation.",
    bgimg: "",
  },
  {
    slug: "cloud-solutions",
    benefits: (
      <>
        <li>
          Flexible Operations: Adapt to changing business needs with a flexible
          and scalable cloud infrastructure.
        </li>{" "}
        <li>
          Improved Collaboration: Foster teamwork and productivity with
          cloud-based collaboration tools.
        </li>
      </>
    ),
    features: (
      <>
        <li>
          Migration Services: Smooth transition to the cloud with minimal
          disruption.
        </li>
        <li>
          {" "}
          Scalability: Easily scale your IT resources to match business
          requirements.
        </li>
        <li>
          {" "}
          Collaboration Tools: Enhanced collaboration through cloud-based
          applications.
        </li>
      </>
    ),
    title: "Cloud Solutions",
    subtitle:
      "Our cloud solutions empower businesses to leverage the flexibility, scalability, and collaboration capabilities of cloud technologies.",
    bgimg: "",
  },
];

const differntiators = [
  {
    title: "Team of Experts",
    img: TExperts,
    details:
      "RemotewebNET Dynamics Ltd. boasts a team of highly skilled and experienced professionals, including web developers, app developers, designers, support staff amongst others. Their collective expertise ensures top-quality deliverables.",
  },
  {
    title: "Client-Centric Approach",
    img: CCentric,
    details:
      "Understanding the unique needs of each client is at the core of the company's philosophy. We collaborate closely with clients, tailoring solutions that align with their business goals and objectives.",
  },
  {
    title: "Cutting-Edge Technology",
    img: CEdge,
    details:
      "The company remains up-to-date with the latest technological advancements and incorporates the best tools and frameworks into their projects. This dedication to innovation keeps their solutions at the forefront of the industry.",
  },
  {
    title: "Quality Assurance",
    img: QAssurance,
    details:
      "RemotewebNET Dynamics Ltd. places a strong emphasis on quality assurance throughout the development lifecycle. Rigorous testing and meticulous attention to detail guarantee reliable and error-free products.",
  },
];
const Journeys = [
  {
    img: ibrahim,
    text: (
      <>
        <b>Meet Ibrahim</b>, a testament to the impact we strive to create. A
        year ago, he joined us with potential waiting to be unleashed. Through
        our dedicated efforts, we empowered him with a laptop, access to our
        training platform, and mentorship that shaped his trajectory. Today,
        Ibrahim stands strong, a symbol of the success that unfolds when talent
        meets opportunity
      </>
    ),
  },
  {
    img: dan,
    text: (
      <>
        Similarly, <b>consider Dan's journey</b>, we didn't just hire Dan; we
        invested in his potential. The challenges we presented to Dan were not
        obstacles but steppingstones, propelling him to heights he once deemed
        unattainable. Dan's story is a testament to the transformative power of
        dedication and focused mentorship.
      </>
    ),
  },
  {
    img: eyo,
    text: (
      <>
        Additionally, <b>Meet Eyo Aniebiet</b>, a remarkable individual who
        embodies the impact we strive to create. When Eyo joined us, he
        possessed latent potential waiting to be unleashed. Through our
        dedicated efforts, we equipped him with both hardware and software
        resources essential for becoming a proficient UI/UX Designer. Eyo's
        journey is also a testament to the transformative power of dedication
        and focused mentorship. Today, Eyo stands strong, showcasing the success
        that unfolds when talent meets opportunity.
      </>
    ),
  },
];

const teamData = [
  { name: "Mr. Michael Ebohen", position: "MD/CEO", img: teamMD },

  {
    name: "Joy Anda",
    img: teamJA,
    position: "Business Development and Operations Manager",
  },
  { name: "Sheriff Ibrahim", img: teamSI, position: "UI/UX Designer" },
  { name: "Ibrahim Dauda", img: ibrahimimg, position: "Software Engineer" },
  { name: "Emmanuel Ada", img: emmanuelimg, position: "Software Engineer" },
];

const whatwedo_data = [
  {
    img: wwd1,
    title: "Unleashing Potential Through IT Support",
    desc: "We understand that technology should be an enabler, not a barrier. Our IT support services are designed to provide a seamless experience, ensuring that individuals and businesses can focus on what matters most while we handle the technical intricacies.",
  },
  {
    img: wwd2,
    title: "Guiding Your Digital Transformation",
    desc: "Embark on a journey of growth with our consultancy services. We offer strategic guidance to individuals and businesses, helping them navigate the vast possibilities of the digital landscape and make informed decisions that lead to success.",
  },
  {
    img: wwd3,
    title: "Crafting Digital Experiences",
    desc: "Our web development services go beyond creating websites; they are about building digital experiences that resonate. We craft solutions that not only meet technical standards but also connect with users on a personal level.",
  },
  {
    img: wwd4,
    title: "Training for Empowerment",
    desc: "Knowledge is power. Our training programs empower individuals to navigate systems and databases confidently. We believe in equipping people with the skills they need to excel in a tech- driven world.",
  },
  {
    img: wwd5,
    title: "Smart Living with Automation",
    desc: "Experience the future with our automation tools. We bring technology into homes and office making everyday living smarter, safer, and more convenient.",
  },
  {
    img: wwd6,
    title: "Managing IT Infrastructure for You",
    desc: "Focus on your goals while we take care of your IT infrastructure. Our management services ensure that your technology backbone is robust, efficient, and ready to support your ambitions.",
  },
  {
    img: wwd7,
    title: "Secure and Connected in the Cloud",
    desc: "Leverage the power of the cloud with our solutions. We provide a secure and connected environment that enables collaboration and innovation.",
  },
  {
    img: wwd8,
    title: "Safeguarding Your Digital World",
    desc: "In an era of digital threats, our cybersecurity services stand as guardians of your digital assets. Trust our process to keep your data secure so you can operate with confidence.",
  },
  {
    img: wwd9,
    title: "Our Commitment Your Partner in Progress",
    desc: "We don't just provide services; we become your partner in progress. Your success is our success, and we are dedicated to helping you achieve your goals.",
  },
  {
    img: wwd10,
    title: "People-Centric Technology",
    desc: "Technology should revolve around people. We're committed to delivering solutions that enhance lives, simplify processes, and contribute to a better-connected world.",
  },
];

export {
  menuItems,
  HeroServices,
  servicesData,
  teamData,
  differntiators,
  clientData,
  Journeys,
  moreData,
  mentorship2025Details,
  productData,
  innovationCategory,
  whatwedo_data,
  footerSocialLinkData,
  coresData,
  services,
};
