import { Menu, Transition } from "@headlessui/react";
import React from "react";
import { Fragment, useEffect, useRef, useState } from "react";
import { FaAngleDown, FaList, FaUserAlt } from "react-icons/fa";
import { useSelector } from "react-redux";

const ListPicker = (props) => {
  var { pickedRole, setPickedRole } = props.hook;
  const [list, setlist] = useState(props?.list);

  useEffect(() => {
    setlist(props?.list);

   }, [props?.list]);

  useEffect(() => {
    setPickedRole(props.defaultvalue);
    console.log(props.defaultvalue);
  }, [props.defaultvalue]);

  return (
    <>
      <Menu
        as="div"
        className={`relative my-2 py-2 ${
          props.maxwidth ? "min-w-[280px]" : ""
        }   ${props.fullwidth ? "min-w-full" : ""}`}
      >
        <div>
          {!props.notitle && (
            <p className=" mb-2 ">
              {" "}
              {props.title} {props.tooltip}
            </p>
          )}
          <Menu.Button className="  px-4 py-2  flex     border bg-faintBlue rounded-[8px] w-full       ">
            <div className="flex flex-items items-center  w-full gap-4">
              <div className=" flex justify-between w-full  items-center p-1">
                <p className="text-gray-600">
                  {" "}
                  {pickedRole ? pickedRole.name : ` Select ${props.title}`}{" "}
                </p>{" "}
                <FaAngleDown color="#777777" />
              </div>

              <div className="flex items-center"></div>
            </div>
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items
            static
            className="origin-top-left z-10 absolute left-0 mt-2 rounded-sm  p-1 bg-white  max-h-[200px]  w-full overflow-x-hidden overflow-y-scroll ring-1 ring-black ring-opacity-5 focus:outline-none"
          >
            <Menu.Item onClick={(e) => e.preventDefault()}>
              {({ active }) => (
                <input

                placeholder="Search..."
                  onChange={(e) => {
                    setlist((prev) =>
                      props.list?.filter((item) =>
                        item.name
                          .toLowerCase()
                          .includes(e.target.value.toLowerCase())
                      )
                    );
                  }}
                  className="  w-full text-gray-600 text-xs p-3 m-2 cursor-pointer truncate hover:bg-customGray "
                />
              )}
            </Menu.Item>

            {list?.map((item) => (
              <Menu.Item>
                {({ active }) => (
                  <p
                    onClick={() => {
                      setPickedRole(item);
                    }}
                    className="  w-full text-gray-600 p-3 m-2 cursor-pointer truncate hover:bg-customGray "
                  >
                    {item.name}
                  </p>
                )}
              </Menu.Item>
            ))}
          </Menu.Items>
        </Transition>
      </Menu>
    </>
  );
};

export { ListPicker };
