import React, { useEffect, useRef, useState } from "react";

import Loader from "../Loader";
import FirstStep from "./Registration/FirstStep";
import StepWizard from "react-step-wizard";
import SecondStep from "./Registration/SecondStep";
import CompetitorReg from "./Registration/CompetitorReg";
import CompletedReg from "./Registration/CompletedReg";

export default function () {
  const [postdata, setpostdata] = useState({});
  const [mainActive, setmainActive] = useState(1);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" }); // Smooth scroll to top
  }, [mainActive]); // Runs every time the pathname changes

  useEffect(() => {
    console.log(postdata);
  }, [postdata]);
  return (
    <div className=" rounded-[20px] border open-sans   border-indigo-600 m-4 md:mx-20 w-[95%]  md:full place-self-center ">
      <div className="header py-[20px] px-8 rounded-t-lg md:!px-[40px] md:!py-[20px]  flex  align-center justify-start text-white">
        <p className="bold text-lg text-[33px]">Event Registration</p>
      </div>
      
      <div className=" p-4   md:py-[50px]  flex justify-center flex-col items-center ">
        <StepWizard
          onStepChange={(stepChange) => {
            var root = document.getElementsByTagName("div");
            var rootArray = Array.from(root);
            rootArray.forEach((element) =>
              element.scrollTo({ top: 0, behavior: "smooth" })
            );
            setmainActive(stepChange.activeStep);
          }}
        >
          <FirstStep setpostdata={setpostdata} postdata={postdata} />
          {postdata.purpose != "Competitior" && (
            <SecondStep setpostdata={setpostdata} postdata={postdata} />
          )}
          {postdata.purpose == "Competitior" && (
            <CompetitorReg setpostdata={setpostdata} postdata={postdata} />
          )}
          <CompletedReg mainActive={mainActive} setpostdata={setpostdata} postdata={postdata} />
        </StepWizard>
      </div>
    </div>
  );
}
