import React, { ReactEventHandler } from "react";
import {
  MdOutlineNavigateBefore,
  MdOutlineNavigateNext,
  MdScanner,
} from "react-icons/md";
import { IoMdQrScanner } from "react-icons/io";
import Button from "./Button";

const NavigateBtns = ({
  actionNext,
  islast,
  actionPrev,
  nextCondition,
  shownext,
  showprev,
}) => {
  return (
    <div className="w-full justify-items-end place-self-end">
      <div className="   w-full md:w-[50%]  m-2 self-end flex justify-end items-center gap-3 md:gap-6">
        {showprev && (
          <Button
          width={300}
            outlined
            text={
              <p className="text-gray-800 ">
                <MdOutlineNavigateBefore className="inline" /> Previous
              </p>
            }
            onBtnClick={actionPrev}
          />
        )}

        {shownext && (
          <Button
          
            disabled={!nextCondition}
            type="submit"
            width={200}
            text={
              !islast ? (
                <>
                  {" "}
                Next{" "}    <MdOutlineNavigateNext className="inline" /> 
                </>
              ) : (
                <>
                  {" "}
                  <IoMdQrScanner className="inline" /> Submit{" "}
                </>
              )
            }
            onBtnClick={actionNext}
          />
        )}
      </div>
    </div>
  );
};

export { NavigateBtns };
