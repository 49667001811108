import { Controller, useForm } from "react-hook-form";
import InputField from "../InputField";
import { Form } from "react-bootstrap";
import {
  MdOutlineRadioButtonChecked,
  MdOutlineRadioButtonUnchecked,
} from "react-icons/md";
import { FaRegUserCircle } from "react-icons/fa";
import { NavigateBtns } from "../NavigateBtns";
import { CiLocationOn } from "react-icons/ci";
import { FaChalkboardUser, FaUser, FaUsers } from "react-icons/fa6";
import { innovationCategory } from "../../../constants/data";

export default ({ setpostdata, nextStep, previousStep, postdata }) => {
  const {
    control,
    handleSubmit,
    watch,
    formState: { errors, isValid },
  } = useForm({
    mode: "onChange", // Ensures validation runs on each change
  });

  const teamno = watch("teamno");

  const Submit = (data) => {
    setpostdata((prev) => {
      return {
        ...prev,
        ...{
          ...data,
        },
      };
    });
  };

  return (
    <div>
      <form
        onSubmit={handleSubmit(Submit)}
        className="flex flex-col  w-[80vw] md:w-full  gap-4 min-h-[300px]"
      >
        <div className="flex w-full gap-4 flex-col md:flex-row">
          <h2 className="text-lg">Registration type</h2>
          <StepPicker
            icon={
              <FaUser
                size={30}
                className="inline text-white p-1  bg-blue-800 rounded-md "
              />
            }
            action={() =>
              setpostdata((prev) => {
                return { ...prev, compmode: "Individual" };
              })
            }
            selected={true}
            title={"Individual"}
          />

          {/* <StepPicker
            icon={
              <FaUsers
                size={30}
                className="inline text-white bg-blue-800 rounded-md p-1 "
              />
            }
            action={() =>
              setpostdata((prev) => {
                return { ...prev, compmode: "Team" };
              })
            }
            selected={postdata.compmode == "Team"}
            title={
              "Team (Team Lead must register first and share a unique code with team members)"
            }
          /> */}
        </div>
        {/* {postdata.compmode == "Team" && (
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 ">
            <InputField
              name="teamname"
              title="Team name"
              placeholder="team name"
              control={control}
              rules={{
                required: "is required",
              }}
            />

            <InputField
              name="teamno"
              title="Number of team members"
              placeholder="number of team members"
              type={"number"}
              maxLength={3}
              control={control}
              rules={{
                required: "is required",
                min: { value: 1, message: "Number must be at least 1" },
                max: { value: 3, message: "Number must not exceed 3" },
              }}
            />
          </div>
        )} */}

        {/* {Array.from({ length: parseInt(teamno) }, (_, i) => (
          <div className=" flex w-full gap-2 flex-col md:flex-row">
            <InputField
              name={`teammember${i}name`}
              title={`Team member (${i + 1}) name`}
              placeholder={`Team member (${i + 1}) name`}
              control={control}
              rules={{
                required: "is required",
              }}
            />
            <InputField
              name={`teammember${i}email`}
              title={`Team member (${i + 1}) email`}
              placeholder={`Team member (${i + 1}) email`}
              control={control}
              rules={{
                required: "is required",
              }}
            />
          </div>
        ))} */}

       
          <div>
            <div>
              <p className="text-customBlue text-lg font-semibold">
                Select Your Innovation Category 
              </p>
              <div className="grid grid-cols-1 md:grid-cols-2">
                <Controller
                  name="category"
                  control={control}
                  rules={{ required: "Option is required" }}
                  render={({ field }) => (
                    <>
                      {innovationCategory.map((item, key) => (
                        <Form.Check
                          key={key}
                          className="m-2 md:text-[20px] font-thin text-[#293586]"
                          type={"radio"}
                          id={`category`}
                          checked={field.value === item}
                          label={item}
                          onChange={(e) => field.onChange(e.target.value)}
                          value={item}
                        />
                      ))}
                    </>
                  )}
                />

{errors.category && <p className="text-red-500 text-xs">{errors.category.message}</p>}

              </div>
            </div>
            <InputField
              islarge
              name="pithctitle"
              title="Pitch title"
              placeholder="Pitch title"
              control={control}
              rules={{
                required: "is required",
              }}
            />
            <InputField
              name="pithcdescription"
              title="Pitch description"
              placeholder="Pitch description"
              control={control}
              islarge
              isTextArea
              rules={{
                required: "is required",
              }}
            />

            <InputField
              name="pitchvideo"
              title="Pitch video (link)"
              placeholder="Pitch video"
              control={control}
              rules={{
                required: "is required",
              }}
            />

            <p className="text-customBlue text-lg font-semibold">
              Would you like to receive event updates via email?  
            </p>
            <div className="flex">
              <Controller
                name="emailnote"
                control={control}
                rules={{ required: "Option is required" }}
                render={({ field }) => (
                  <>
                    <Form.Check
                      className="m-2 md:text-[20px] font-thin text-[#293586]"
                      type={"radio"}
                      id={`emailnote`}
                      label={`Yes`}
                      checked={field.value === "Yes"}
                      onChange={(e) => field.onChange(e.target.value)}
                      value="Yes"
                    />

                    <Form.Check
                      className="m-2 md:text-[20px] font-thin text-[#293586]"
                      type={"radio"}
                      id={`emailnote`}
                      checked={field.value === "No"}
                      label={`No`}
                      onChange={(e) => field.onChange(e.target.value)}
                      value="No"
                    />
                  </>
                )}
              />

{errors.emailnote && <p className="text-red-500 text-xs">{errors.emailnote.message}</p>}

            </div>
            <p className="text-customBlue text-lg font-semibold">
              Do you agree to the competition/event terms & conditions?  
            </p>
            <div className="flex">
              <Controller
                name="agree_terms"
                control={control}
                rules={{ required: "Option is required" }}
                render={({ field }) => (
                  <>
                    <Form.Check
                      className="m-2 md:text-[20px] font-thin text-[#293586]"
                      type={"radio"}
                      id={`agree_terms`}
                      label={`Yes`}
                      checked={field.value === "Yes"}
                      onChange={(e) => field.onChange(e.target.value)}
                      value="Yes"
                    />

                    <Form.Check
                      className="m-2 md:text-[20px] font-thin text-[#293586]"
                      type={"radio"}
                      id={`agree_terms`}
                      checked={field.value === "No"}
                      label={`No`}
                      onChange={(e) => field.onChange(e.target.value)}
                      value="No"
                    />
                  </>
                )}
              />

{errors.agree_terms && <p className="text-red-500 text-xs">{errors.agree_terms.message}</p>}

            </div>
          </div>
        

        <NavigateBtns
          shownext={true}
          showprev={true}
          actionPrev={() => {
            previousStep();
          }}
          nextCondition={true}
          actionNext={() => {
 
 
 if(isValid){
  nextStep();

 }         
          }}
        />
      </form>
    </div>
  );
};

export const StepPicker = ({ selected, title, action, icon }) => {
  return (
    <div
      onClick={() => {
        action();
      }}
      className={`rounded-xl border text-lg  flex px-6 py-3 gap-2   border-primary ${
        selected ? "bg-faintBlue  border-mainPink " : " border-gray-300"
      }`}
    >
      <p className="text-lg cursor-pointer text-customBlue font-semibold">
        {icon} {title}{" "}
        {selected ? (
          <MdOutlineRadioButtonChecked className="inline text-mainPink" />
        ) : (
          <MdOutlineRadioButtonUnchecked className="inline text-mainPink" />
        )}
      </p>
    </div>
  );
};
