import { barelogo } from "../../constants/imports";

export default () => {
  return (
    <div className="hero">

 
    <div className="heroevent w-full min-h-[80vh] flex justify-center pb-4 flex-col items-center md:px-20">
      <div className="w-full   bg-white/10 backdrop-blur-sm p-3 z-10 rotate-in  rounded-lg flex justify-center items-center">
        <div className="h-[400px] text-white zoom-in w-[400px] rounded-full bg-mainPink/60 p-4 flex justify-center items-center flex-col">
       <img  width={50} className="" src={barelogo}/>
        <p className="text-[22px] font-[400] ">Mentorship Programme</p>
    <hr className="w-[300px]"/>
       
        
        <h2 className="font-bold text-[30px]  text-white">July 2025
            </h2>
            
            </div>
      </div>
    </div>       
    </div>
  );
};
