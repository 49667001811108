import { useForm } from "react-hook-form";
import InputField from "../InputField";
import { Form, Spinner } from "react-bootstrap";
import {
  PiLinkedinLogo,
  PiLinkedinLogoDuotone,
  PiLinkedinLogoLight,
} from "react-icons/pi";

import { FaRegUserCircle } from "react-icons/fa";
import { NavigateBtns } from "../NavigateBtns";
import { CiLocationOn } from "react-icons/ci";
import {
  FaChalkboardUser,
  FaFacebook,
  FaInstagram,
  FaUser,
  FaUsers,
  FaXTwitter,
} from "react-icons/fa6";
import {
  innovationCategory,
  mentorship2025Details,
} from "../../../constants/data";
import { regcheck } from "../../../constants/imports";
import { IoMail } from "react-icons/io5";
import Button from "../Button";
import { MdOutlineNavigateBefore } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import Loader from "../../Loader";
import { useEffect, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { newRegistration } from "../../../api/events";
import { toast } from "react-toastify";

export default ({
  setpostdata,
  nextStep,
  mainActive,
  previousStep,
  postdata,
}) => {
  const navigate = useNavigate();
  const [isloading, setisloadding] = useState(true);
  const { mutateAsync, data, isPending, error } = useMutation({
    mutationFn: (regdata) => {
      newRegistration(regdata);
    },
    onError: (e) => {
      toast.error(e);
    },

    onSuccess: () => {
      setisloadding(false);
    },
  });

  const onSubmit = async () => {
    try {
      await mutateAsync({
        full_name: postdata.name ?? "",
        email: postdata.email ?? "",
        phone_number: postdata.phone ?? "",
        gender: postdata.gender ?? "",
        country: postdata.country ?? "",
        state: postdata.state ?? "",
        purpose: postdata.purpose ?? "",
        attendance_mode: postdata.attendancemode ?? "Do not apply",
        want_certificate: true,
        want_mentorship: true, //postdata.mentorship,
        reg_type: postdata.compmode ?? "Do not apply",
        project_idea: postdata.pithctitle,
        document: postdata.pitchvideo,
        category: postdata.category,
        sub_category: "TECH",
        team_no: 0,
      });
    } catch (e) {}
  };

  useEffect(() => {
    if (mainActive == 3) {
      setisloadding(true);
      setTimeout(async () => {
        await onSubmit();
      }, 3000);
    }
  }, [mainActive]);

  return (
    <div>
      {!(isPending || isloading) ? (
        <div className="flex justify-center items-center flex-col gap-3">
          <img className=" animate-pulse" src={regcheck} />

          <h2 className="text-lg">Thank You for Registering!</h2>
          <p className="text-customBlue">
            Your registration has been successfully submitted! We're excited to
            have you on board
          </p>

          <div className="p-3 border border-gray-300 rounded-lg">
            <IoMail
              size={30}
              className="inline text-white p-1 rounded-lg  bg-customBlue"
            />{" "}
            <p className="inline text-sm">
              You will receive a confirmation email with venue details. Please
              arrive early to check in.
            </p>
          </div>

          <p className="text-black text-sm">
            Want to stay updated? Follow us on
          </p>
          <div className=" flex gap-3   text-xl">
            <a href="https://web.facebook.com/profile.php?id=100095177652562&mibextid=qi2Omg&rdid=joRnnsK1hMAlR7aM&share_url=https%3A%2F%2Fweb.facebook.com%2Fshare%2FSYnwZY6YFnprTdDD%2F%3Fmibextid%3Dqi2Omg%26_rdc%3D1%26_rdr#">
              <FaFacebook size={40} />
            </a>
            <a href="https://www.instagram.com/remotewebnetdynamics">
              <FaInstagram size={40} />
            </a>
            <a href="https://x.com/Rwndynamics">
              {" "}
              <FaXTwitter size={40} />
            </a>
            <a href="https://www.linkedin.com/company/remotewebnet/posts/?feedView=all">
              {" "}
              <PiLinkedinLogoLight size={40} />
            </a>
          </div>

          <div className="flex scale-75 gap-3">
            <Button
              width={200}
              outlined
              text={
                <p className="text-gray-800">
                  <MdOutlineNavigateBefore className="inline" /> New submision
                </p>
              }
              onBtnClick={() => {
                window.location.reload();
              }}
            />
            <Button
              width={200}
              outlined
              text={<p className="text-gray-800">Home</p>}
              onBtnClick={() => {
                navigate("/");
              }}
            />
          </div>
        </div>
      ) : (
        <div className="py-10 flex justify-center items-center flex-col gap-3">
          <Spinner
            className="h-[200px] w-[200px]"
            size={"lg"}
            variant="primary"
            animation="border"
          />
          <p className="animate-pulse text-center">Submitting...</p>
        </div>
      )}
    </div>
  );
};
