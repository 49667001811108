import React, { useEffect, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";

function CountdownTimer({ eventDate }) {
  const todayDate = new Date();
  const calculateTimeLeft = () => {
    const diffMs = eventDate.getTime() - new Date().getTime();

    if (diffMs <= 0) {
      return { days: 0, hours: 0, minutes: 0, seconds: 0 }; // Countdown reached
    }

    return {
      days: Math.floor(diffMs / (1000 * 60 * 60 * 24)),
      hours: Math.floor((diffMs % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)),
      minutes: Math.floor((diffMs % (1000 * 60 * 60)) / (1000 * 60)),
      seconds: Math.floor((diffMs % (1000 * 60)) / 1000),
    };
  };
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer); // Cleanup on unmount
  }, [eventDate]);

  const Item = ({ value, subvalue }) => (
    <div className="flex justify-center flex-col rounded-lg bg-black/20 p-3 items-center text-white">
      {subvalue == "Seconds" ? (
        <AnimatePresence mode="">
          <motion.span
            key={timeLeft.seconds}
            initial={{ y: "-100%", opacity: 0 }}
            animate={{ y: "0%", opacity: 1 }}
            exit={{ y: "-100%", x:"-100%", opacity: 0 }}
            transition={{ duration: 0.3 }}
            className="  w-full"
          >
            {" "}
            <p className="font-bold text-[23px] text-center transition delay-500 text-[#EBE126] ">
              {value}
            </p>
          </motion.span>
        </AnimatePresence>
      ) : (
        <p className="font-bold text-[#EBE126] text-[23px] text-center transition delay-500 ">
          {value}
        </p>
      )}

      <small>{subvalue}</small>
    </div>
  );

  return (
    <div className="flex gap-4 p-4">
      <Item value={timeLeft.days} subvalue={"Days"} />
      <Item value={timeLeft.hours} subvalue={"Hours"} />
      <Item value={timeLeft.minutes} subvalue={"Minuites"} />
      <Item value={timeLeft.seconds} subvalue={"Seconds"} />
    </div>
  );
}

export default CountdownTimer;
