import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { About, Home, Products, Services } from "./pages";
import { useState } from "react";
import Chatbotbtn from "./component/Chatbot/Chatbotbtn";
import Chatbotview from "./component/Chatbot/Chatbotview";
import HireUs from "./pages/HireUs";
import Impact from "./pages/Impact";
import Whyus from "./pages/Whyus";
import Blog from "./pages/Blog";
import Loader from "./component/Loader";
import { useSelector } from "react-redux";
import Partners from "./pages/Partners";
import { FaWhatsapp } from "react-icons/fa6";
import Events from "./pages/Events";
import Mentorship2025 from "./pages/Events/Mentorship2025/Mentorship2025";
import Register from "./pages/Events/Mentorship2025/Register";
import { useEffect } from "react";
import { QueryClient, QueryClientProvider,} from '@tanstack/react-query'
const App = () => {
  var isloading = useSelector((state) => state.loading);

  var [showcb, setshowcb] = useState(true);

  const queryclient = new QueryClient();

  return (
    <>
      <QueryClientProvider client={queryclient}>
        {isloading && <Loader />}
        <Router>
          <Routes>
            <Route path="/" exact element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/products" element={<Products />} />
            <Route path="/partners" element={<Partners />} />
            <Route path="/blog" element={<Blog />} />
            <Route path="/hire-us" element={<HireUs />} />
            <Route path="/impact" element={<Impact />} />
            <Route path="/why-us" element={<Whyus />} />

            <Route path="/events" element={<Events />} />
            <Route path="/mentorship2025" element={<Mentorship2025 />} />
            <Route path="/mentorship2025/register" element={<Register />} />

            <Route path="/:slug" element={<Services />} />
          </Routes>
        </Router>
        <ToastContainer />
        <FaWhatsapp
          onClick={() => {
            window.open("https://wa.link/q9125g");
          }}
          size={30}
          className="wa-container animate-bounce  "
        />
        {showcb ? (
          <Chatbotbtn
            click={() => {
              setshowcb(false);
            }}
          />
        ) : (
          <Chatbotview
            cancel={() => {
              setshowcb(true);
            }}
          />
        )}
      </QueryClientProvider>
    </>
  );
};

export const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" }); // Smooth scroll to top
  }, [pathname]); // Runs every time the pathname changes

  return null; // This component does not render anything
};

export default App;
