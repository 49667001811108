import { Controller, useForm } from "react-hook-form";
import InputField from "../InputField";
import { Form } from "react-bootstrap";
import {
  MdOutlineRadioButtonChecked,
  MdOutlineRadioButtonUnchecked,
} from "react-icons/md";
import { FaRegUserCircle } from "react-icons/fa";
import { NavigateBtns } from "../NavigateBtns";
import { CiLocationOn } from "react-icons/ci";
import { FaChalkboardUser } from "react-icons/fa6";
import { StepPicker } from "./FirstStep";

export default ({ setpostdata, nextStep, previousStep, postdata }) => {
  const {
    control,
    handleSubmit,
    register,
    formState: { errors, isValid },
  } = useForm({
    mode: "onChange", // Ensures validation runs on each change
  });

  const Submit = (data) => {
    setpostdata((prev) => {
      return {
        ...prev,
        ...{
          ...data,
        },
      };
    });
  };

  return (
    <div className="w-full">
      <form
        onSubmit={handleSubmit(Submit)}
        className="flex flex-col w-[80vw] md:w-full   gap-4 min-h-[400px] "
      >
        {/* <div className="   flex w-full flex-col md:flex-row gap-4 ">
          <StepPicker
            icon={
              <CiLocationOn
                size={30}
                className="inline text-white p-1  bg-blue-800 rounded-md "
              />
            }
            action={() =>
              setpostdata((prev) => {
                return { ...prev, attendancemode: "Physical" };
              })
            }
            selected={postdata.attendancemode == "Physical"}
            title={"Physical attendance"}
          />

          <StepPicker
            icon={
              <FaChalkboardUser
                size={30}
                className="inline text-white bg-blue-800 rounded-md p-1 "
              />
            }
            action={() =>
              setpostdata((prev) => {
                return { ...prev, attendancemode: "Virtual" };
              })
            }
            selected={postdata.attendancemode == "Virtual"}
            title={"Virtual attendance"}
          />
        </div> */}

       
          <div>
            <div>
              <p className="text-customBlue text-lg font-semibold">
                What aspects of the event interest you most? 
              </p>

              <Controller
                name="interest"
                control={control}
                rules={{ required: "Option is required" }}
                render={({ field }) => (
                  <>
                    <Form.Check
                      className="m-2 md:text-[20px] font-thin text-[#293586]"
                      type={"radio"}
                      id={`interest`}
                      label={`Keynote Speaker Sessions`}
                      onChange={(e) => field.onChange(e.target.value)}
                      value="Keynote Speaker Sessions"
                    />

                    <Form.Check
                      className="m-2 md:text-[20px] font-thin text-[#293586]"
                      type={"radio"}
                      id={`interest`}
                      label={`Panel Discussions`}
                      onChange={(e) => field.onChange(e.target.value)}
                      value="Panel Discussions"
                    />
                    <Form.Check
                      className="m-2 md:text-[20px] font-thin text-[#293586]"
                      type={"radio"}
                      id={`interest`}
                      label={`Networking Opportunities`}
                      onChange={(e) => field.onChange(e.target.value)}
                      value="Networking Opportunities"
                    />
                    <Form.Check
                      className="m-2 md:text-[20px] font-thin text-[#293586]"
                      type={"radio"}
                      id={`interest`}
                      label={`Workshops & Training`}
                      onChange={(e) => field.onChange(e.target.value)}
                      value="Workshops & Training"
                    />
                    <Form.Check
                      className="m-2 md:text-[20px] font-thin text-[#293586]"
                      type={"radio"}
                      id={`interest`}
                      label={`Tech Exhibition`}
                      onChange={(e) => field.onChange(e.target.value)}
                      value="Tech Exhibition"
                    />
                  </>
                )}
              />

{errors.interest && <p className="text-red-500 text-xs">{errors.interest.message}</p>}

            </div>

            <div>
              <p className="text-customBlue text-lg font-semibold">
                Would you like to volunteer for the event? 
              </p>
              <Controller
                name="volunteer"
                control={control}
                rules={{ required: "Option is required" }}
                render={({ field }) => (
                  <>
                    <Form.Check
                      className="m-2 md:text-[20px] font-thin text-[#293586]"
                      type={"radio"}
                      id={`volunteerop`}
                      checked={field.value === "Yes"}
                      label={`Yes`}
                      onChange={(e) => field.onChange(e.target.value)}
                      value="Yes"
                    />

                    <Form.Check
                      className="m-2 md:text-[20px] font-thin text-[#293586]"
                      type={"radio"}
                      id={`volunteerop`}
                      checked={field.value === "No"}
                      label={`No`}
                      onChange={(e) => field.onChange(e.target.value)}
                      value="No"
                    />
                  </>
                )}
              />

{errors.volunteer && <p className="text-red-500 text-xs">{errors.volunteer.message}</p>}

            </div>
            <div>
              <p className="text-customBlue text-lg font-semibold">
                Are you interested in mentorship opportunities?  
              </p>

              <Controller
                name="mentorship"
                control={control}
                rules={{ required: "Option is required" }}
                render={({ field }) => (
                  <>
                    <Form.Check
                      className="m-2 md:text-[20px] font-thin text-[#293586]"
                      type={"radio"}
                      id={`mentorship`}
                      label={`Yes, as a mentor`}
                      onChange={(e) => field.onChange(e.target.value)}
                      value="Yes, as a mentor"
                    />

                    <Form.Check
                      className="m-2 md:text-[20px] font-thin text-[#293586]"
                      type={"radio"}
                      id={`mentorship`}
                      label={`Yes, as a mentee`}
                      onChange={(e) => field.onChange(e.target.value)}
                      value="Yes, as a mentee"
                    />
                    <Form.Check
                      className="m-2 md:text-[20px] font-thin text-[#293586]"
                      type={"radio"}
                      id={`mentorship`}
                      label={`No`}
                      onChange={(e) => field.onChange(e.target.value)}
                      value="No"
                    />
                  </>
                )}
              />

{errors.mentorship && <p className="text-red-500 text-xs">{errors.mentorship.message}</p>}

            </div>
          </div>
      

        <NavigateBtns
          shownext
          showprev
          actionPrev={() => {
            previousStep();
          }}
          nextCondition={ true }
          actionNext={() => {

            if(isValid){
              nextStep();
            }
           
          }}
        />
      </form>
    </div>
  );
};

 
