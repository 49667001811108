import React, { FC, ReactEventHandler } from "react";
 

const Button = ({
  outlined,
  text,
  onBtnClick,
  type,
  disabled,
  width,
  Icon,
 
}) => {
  return (
    <button
    disabled={disabled}
      onClick={onBtnClick}
      type={type?type:"button"}
      className={`inline rounded-[8px] !text-white  hover:!text-gray-700 ${
        outlined ? " border !border-mainPink  bg-white/0   !text-mainPink" : `${disabled ?'bg-gray-300':'bg-mainPink'}`
      } ${
        width ? " w-[150px] md:w-[" + width + "px]" : "w-full"  }   transition cursor-pointer duration-200  focus:ring-opacity-50 py-3  px-4   text-xs my-3 shadow-sm hover:shadow-md font-[500]  hover:bg-white hover:border hover:border-mainPink text-center    `}
    >
      <div className="flex items-center justify-center">
        {Icon && (
          <Icon
            size={20}
            className={`${
              outlined ? "text-mainPink  " : "text-white "
            } mr-2 `}
          />
        )}{" "}
        <span className={`inline-block`}>
          {text}
        </span>
      </div>
    </button>
  );
};

export default Button;
