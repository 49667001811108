import React, { useEffect, useState } from "react";
import { logo } from "../../constants/imports";
import { Link } from "react-router-dom";
import { Events, menuItems } from "../../constants/data";
import { FaAngleDown, XIcon } from "react-icons/fa6";
import { MdOutlineCancel } from "react-icons/md";
import { FiMenu } from "react-icons/fi";
import "./Styles/Header.css";
import DropDownListItem from "./DropDownListItem";
import Dropdown from "react-bootstrap/Dropdown";
import { useLocation } from "react-router-dom";

import HireUs from "../../component/HireUs/HireUsButton";
// import { MenuIcon, XIcon } from "@heroicons/react/outline";
const Header = () => {
  const [nav, setNav] = useState(false);
  const handleClick = () => setNav(!nav);
  const [onShowServices, setOnShowServices] = useState(false);
  const location = useLocation();
  const [onshowedtech, setshowedtech] = useState(false);
  const [onshowevents, setshowevents] = useState(false);
  const [onshowabout, setonshowabout] = useState(false);
  const handleClose = () => setNav(!nav);
  const servicesdata = [
    { title: "IT Services", path: "/it-support" },
    { title: "Cyber Security", path: "/cyber-security" },
    { title: "Home Automation", path: "/smart-home" },
    { title: "Software Development", path: "/software" },
    { title: "Consultancy", path: "/consultancy" },
    {
      title: "Digital Transformation Services",
      path: "/digital-transformation",
    },
    { title: "Cloud Solutions ", path: "/cloud-solutions" },
    { title: "IT Infrastructure Management", path: "/it-infrastucture" },
  ];

  const aboutdata = [
    { title: "Mission and Vision", path: "/about#mission" },
    { title: "What We Do", path: "/about#whatwedo" },
    { title: "Core Values", path: "/about#corevlues" },
    { title: "Why RemotewebNET Dynamics", path: "/why-us" },
    { title: "Our Team", path: "/about#team" },
  ];
  const edtech = [
    { title: "Mentorship", path: "/impact" },
    { title: "Trainings", path: "/trainings" },
  ];

  useEffect(() => {
    setOnShowServices(false);
    setonshowabout(false);
    setshowedtech(false);
  }, [location]);
  return (
    <>
      <div
       
        className="nav-area z-10 flex justify-between  bg-black/90 items-center py-4 md:px-15 px-8"
      >
        <Link to={"/"}>
          <img width={200} src={logo} alt="Remote WebNET Dynamics" />
        </Link>

        <div className="flex">
          {/* //service item */}

          <div
            style={{ cursor: "pointer" }}
            onMouseLeave={(e) => {
              console.log(e);
              setOnShowServices(!onShowServices);
            }}
            onMouseEnter={(e) => {
              console.log(e);
              setOnShowServices(!onShowServices);
            }}
            className="  mr-2 block transition ease-in-out delay-150  rounded-lg  hover:bg-faintprimary py-3 px-4 flex items-center text-xs font-normal text-white "
          >
            <div class=" md:flex hidden   items-center ">
              <a href="#">
                Services <FaAngleDown className="text-white inline" size={13} />
              </a>

              {onShowServices && (
                <div className=" shadow  absolute top-[60px]  ">
                  <div className=" py-2  "></div>
                  <ul className=" grid grid-cols-2 gap-2 text-lg font-lg bg-white z-[100] rounded-md py-2 px-4">
                    {servicesdata.map((item, index) => (
                      <DropDownListItem
                        key={index}
                        item={item}
                        index={index}
                        length={servicesdata.length}
                      />
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </div>
          {/* // about */}
          <div
            style={{ cursor: "pointer" }}
            onMouseLeave={(e) => {
              console.log(e);
              setonshowabout(!onshowabout);
            }}
            onMouseEnter={(e) => {
              console.log(e);
              setonshowabout(!onshowabout);
            }}
            className=" mr-2 block transition ease-in-out delay-150   rounded-lg  hover:bg-faintprimary py-3 px-4 flex items-center text-xs font-normal text-white "
          >
            <div class=" md:flex hidden justify-between items-center">
              <a href="#">
                About Us{" "}
                <FaAngleDown className="text-white ml-2 inline" size={13} />
              </a>

              {onshowabout && (
                <div className=" shadow absolute top-[60px]  ">
                  <div className=" py-2  "></div>
                  <ul className=" grid grid-cols-2 gap-2 text-lg font-lg rounded-md bg-white z-[100] py-2 px-4">
                    {aboutdata.map((item, index) => (
                      <DropDownListItem
                        key={index}
                        item={item}
                        index={index}
                        length={aboutdata.length}
                      />
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </div>
          {/* //ed-tech */}
          {/* <div
            style={{ cursor: "pointer" }}
            onMouseLeave={(e) => {
              setshowedtech(!onshowedtech);
            }}
            onMouseEnter={(e) => {
              setshowedtech(!onshowedtech);
            }}
            className="  block transition ease-in-out delay-150   rounded-lg  hover:bg-faintprimary py-3 px-4 flex items-center text-xs font-normal text-white "
          >
            <div class=" md:flex hidden justify-between items-center">
              <a href="#">
                Edu Tech{" "}
                <FaAngleDown className="text-white ml-2 inline" size={13} />
              </a>

              {onshowedtech && (
                <div className=" shadow absolute top-[60px] ">
                  <div className=" py-2  "></div>
                  <ul className=" grid grid-cols-2 gap-2 text-lg font-lg bg-white rounded-md z-[100] py-2 px-4">
                    {edtech.map((item, index) => (
                      <DropDownListItem
                        key={index}
                        item={item}
                        index={index}
                        length={edtech.length}
                      />
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </div> */}
          {/* Events */}
          <div
            style={{ cursor: "pointer" }}
            onMouseLeave={(e) => {
              setshowevents(!onshowevents);
            }}
            onMouseEnter={(e) => {
              setshowevents(!onshowevents);
            }}
            className="  block transition ease-in-out delay-150   rounded-lg  hover:bg-faintprimary py-3 px-4 flex items-center text-xs font-normal text-white "
          >
            <div class=" md:flex hidden justify-between items-center">
              <a href="#">
                Events
                <FaAngleDown className="text-white ml-2 inline" size={13} />
              </a>

              {onshowevents && (
                <div className=" shadow absolute top-[60px] ">
                  <div className=" py-2  "></div>
                  <ul className="  gap-2 text-lg font-lg bg-white rounded-md z-[100] py-2 px-4">
                    {Events.map((item, index) => (
                      <DropDownListItem
                        key={index}
                        item={item}
                        index={index}
                        length={Events.length}
                      />
                    ))}
                  </ul>
                </div>
              )}
            </div>
          </div>

          {/* Menu Items */}
          <div className="  md:flex hidden justify-between items-center">
            {menuItems.map((item, index) => (
              <Link
                key={index}
                className="transition ease-in-out delay-150  block rounded-lg mr-53 text-16 text-white  hover:bg-faintprimary py-2 px-4 "
                to={item.path}
              >
                <div className="hover:text-primary">{item.title}</div>
              </Link>
            ))}

            <Link to="/hire-us">
              <HireUs />
            </Link>

            {/* <Link to={"/hire-us"} className="primary-btn ml-4">
          Hire US
        </Link> */}
          </div>
        </div>
        {/* Mobile Icon */}
        <div className="md:hidden block" onClick={handleClick}>
          {!nav ? (
            <FiMenu className=" text-3xl w-10" color="#fff" />
          ) : (
            <MdOutlineCancel color="#fff" className=" text-3xl w-10" />
          )}
        </div>
      </div>

      {nav && (
        <div className=" flex flex-column justify-start items-start  p-4 bg-[#333333] ">
          <Dropdown className="mb-2" data-bs-theme="dark">
            <Dropdown.Toggle variant="success" id="dropdown-basic">
              Services
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {servicesdata.map((item, index) => (
                <Dropdown.Item eventKey={index}>
                  <Link
                    key={index}
                    className="transition ease-in-out delay-150  block rounded-lg mr-5 text-16 text-white  hover:bg-faintprimary py-2 px-4 "
                    to={item.path}
                  >
                    <div className="hover:text-primary">{item.title}</div>
                  </Link>
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
          <Dropdown className="mb-2" data-bs-theme="dark">
            <Dropdown.Toggle variant="success" id="dropdown-basic">
              About Us
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {aboutdata.map((item, index) => (
                <Dropdown.Item eventKey={index}>
                  <Link
                    key={index}
                    className="transition ease-in-out delay-150  block rounded-lg mr-5 text-16 text-white  hover:bg-faintprimary py-2 px-4 "
                    to={item.path}
                  >
                    <div className="hover:text-primary">{item.title}</div>
                  </Link>
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>

          {menuItems.map((item, index) => (
            <Link
              key={index}
              className="transition ease-in-out delay-150  block rounded-lg mr-5 text-16 text-white  hover:bg-faintprimary py-2"
              to={item.path}
            >
              <div className="hover:text-primary">{item.title}</div>
            </Link>
          ))}

          <Link to="/hire-us">
            <HireUs className="place-content-center place-self-center" />
          </Link>
        </div>
      )}
    </>
  );
};

export default Header;
