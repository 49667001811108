import React, { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import { FaCheck } from "react-icons/fa";
import { IoMdClose, IoMdEye, IoMdEyeOff } from "react-icons/io";
import { MdCancel } from "react-icons/md";

 

const InputField= ({
  control,
  name,
  isDisabled,
  rules = {},
  placeholder,
  title,
  islarge,
  type,
  tooltip,
  defaultvalue,
  maxLength,
  showCount,
  isTextArea,
  style,
  contClass,
}) => {
  const [inptype, settype] = useState(type);
  const [passvisible, setpassvisible] = useState(false);
  const [focused, setfocused] = useState(false);
 
  return (
    <Controller
      control={control}
      name={name}
      defaultValue={defaultvalue}
      rules={rules}
      render={({
        field: { value = defaultvalue, onChange },
        fieldState: { error },
      }) => (
        <div className={`${contClass ? contClass : ""}  w-[250px]  ${islarge?" md:w-[1000px]":" md:!w-[500px] "}  relative`}>
          {title && (
            <div className="flex items-center justify-between ">
              <p className=" font-medium m-2 text-[#4E589C] flex justify-center items-center">{title}      {tooltip}</p>
              <div>
                {showCount && (
                  <p className="">{`${
                    value?.length > 0 ? value?.length : 0
                  }${maxLength ? "/" + maxLength : ""}`}</p>
                )}
              </div>

         
            </div>
          )}

          {isTextArea ? (
            <textarea
              value={value}
              placeholder={placeholder}
              onChange={(text) => {
                
                onChange(text);
              }}
              className={`block bg-faintBlue    py-3 px-4 custom-outline rounded-md w-full  border ${
                error ? "border-red-500" : "border-gray-200"
              }`}
              style={style}
              maxLength={maxLength || undefined}
              rows={3}
            ></textarea>
          ) : (
            <div
              className={`flex justify-center items-center border-2 ${
                focused ? " border-primary shadow" : " border-gray-200   "
              }  bg-fainblue rounded-lg `}
            >
              <input
                type={inptype || "text"}
                value={value}
                disabled={isDisabled}
                id={name}
                onFocus={() => {
                  setfocused(true);
                }}
 
                 onBlur={() => {
                  setfocused(false);
                }}
                placeholder={placeholder}
                onChange={(text) => {
                  onChange(text);
              
                }}
                style={style}
                className={` block bg-faintBlue  py-3 custom-outline	px-4 rounded-lg   w-full  text-[#4E589C] ${
                  error ? "border-red-500" : ""
                }`}
                maxLength={maxLength || undefined}
              />
              {type == "password" &&
                (passvisible ? (
                  <IoMdEye
                    onClick={() => {
                      settype("text");
                      if (inptype == "password") {
                        settype("text");
                      } else {
                        settype("password");
                      }
                      setpassvisible(!passvisible);
                    }}
                    size={20}
                    className=" text-textbg inline m-1 "
                  />
                ) : (
                  <IoMdEyeOff
                    onClick={() => {
                      settype("text");
                      if (inptype == "password") {
                        settype("text");
                      } else {
                        settype("password");
                      }
                      setpassvisible(!passvisible);
                    }}
                    size={20}
                    className=" text-textbg inline m-1 "
                  />
                ))}
            </div>
          )}

          {error && (
            <span className="text-[#ff0000] text-xs self-stretch">
              {error.message || "Error"}
            </span>
          )}
        </div>
      )}
    />
  );
};

export default InputField;
