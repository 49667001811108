import Events from "../component/Events/Events";
import { Header, Footer } from "../component/Layout";
export default function () {
  return (
    <div>
      <Header />
      <Events />
      <Footer />
    </div>
  );
}
