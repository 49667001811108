import { Footer } from "flowbite-react";
import HireUsForm from "../../../component/HireUs/HireUsForm";
import { Header } from "../../../component/Layout";
import RegisterForm from "../../../component/Events/RegisterForm";
import { ScrollToTop } from "../../../App";

export default () => {
  return (
    <div className="w-screen">
      <Header />
              
      
      <RegisterForm />
      <Footer />
    </div>
  );
};
