import React from "react";
import { Carousel, Carouselx } from "./Carousel";
import Button from "./Button";
import CountdownTimer from "./CountdownTimer";

const EventsHero = () => {
  return (
    <div className="hero w-full min-h-[90vh] flex justify-center pb-4 flex-col items-center px-20">
         <CountdownTimer eventDate={new Date("2025-07-05T12:00:00")}/>
      <div className="px-4 w-full flex justify-center items-center">
        <Carouselx />
      </div>

      
    </div>
  );
};

export default EventsHero;
