import axios from "axios";
import { baseURL } from "../utils/base";

export const newRegistration = async (postData) => {
    
  
    try {
      const response = await axios.post(`${baseURL}/event/registration`, postData);
      return response.data;
    } catch (error) {
      console.log(error?.response);
      if (error?.response?.data?.message === undefined) {
        throw error.message;
      } else {
        throw error?.response?.data?.message;
      }
    }
  
  }