import { useState } from "react"


 
const usePickerhook=()=>{

var [pickedRole, setPickedRole]=useState ({name:"",code:""})



    return {pickedRole,setPickedRole}
}

export {usePickerhook}