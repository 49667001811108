import { Carousel } from "flowbite-react";
import { speakerimg1 } from "../../constants/imports";
import { MdArrowBackIos, MdArrowForward, MdArrowForwardIos } from "react-icons/md";

export default () => {
  return (
    <div className="bg-[#fafafa] md:p-4 p-2 my-4">
        <h2 className="font-bold text-2xl md:text-[42px] capitalize ">Meet the incredible speakers</h2>
      <Carousel style={{padding:"30px"}}
        leftControl={<MdArrowBackIos size={20} className="text-primary" />}
        rightControl={<MdArrowForwardIos size={20} className="text-primary" />}
      >
        <SpeakerItem />
        <SpeakerItem />
        <SpeakerItem />
      </Carousel>
    </div>
  );
};

const SpeakerItem = ({ name, proffession, desc }) => (
  <div className="flex bg-[#F5F5FF] rounded-xl flex-col md:flex-row mx-4 gap-3   p-3 md:w-4/6 border border-primary/30">
    <img className="rounded-xl " src={speakerimg1} />
    <div className="flex flex-col justify-center items-start  gap-2">
      <p className="text-[24px] text-[#0F0B0C] font-bold">Michael Knight</p>
      <p className="text-sm font-bold text-mainPink uppercase">Marketer</p>
      <p className="  font-[400] leading-7">
        This programme is more than just a learning opportunity—it’s your
        launchpad for accelerated career growth. Connect with like-minded peers,
        engage in thought-provoking conversations, and gain exclusive insights
        that will equip you to thrive in the competitive tech landscape.
      </p>
    </div>
  </div>
);
